/**
 *  lista dostepnych adresow/programow, na ktorych udostepniamy API. produkcyjne jak i testowe i lokalne
 * @type {[{prod: string, test: string, name: string, local: string}]}
 */
const API_URL_LIST = [
  {
    name: 'gama',
    test: 'https://whitelabel.pc.civ.pl/api/mobile/',
    local: 'http://nowypanel.lvh.me/api/mobile/',
    prod: 'https://panel-gama.pc.civ.pl/api/mobile/',
  },
  {
    name: 'cold',
    local: 'http://nowypanel-cold.lvh.me/api/mobile/',
    test: 'https://cold-lux-test.pc.civ.pl/api/mobile/',
    prod: 'https://cold-lux.pc.civ.pl/api/mobile/',
  },
  {
    name: 'justynka',
    local: 'http://justynka.lvh.me/api/mobile/',
    test: '',
    prod: 'https://justynka.pc.civ.pl/api/mobile/',
  },
];

/**
 * pobor url srodowiska
 * @param program_name - nazwa programu
 * @param env - srodowisko
 * @returns {{name: string, is_test: boolean, value: string}[]}
 */
function getProgramApiUrl(program_name = '', env = '') {
  const program = API_URL_LIST.find((x) => x.name === program_name);
  if (typeof program !== 'object') {
    console.error(`Brak programu ${program_name}. Zajrzyj do pliku api.js`);
  }
  return program[env];
}

/**
 * lista dostepnych URL stron programow, testowych jak i produkcyjnych
 * @type array
 */
const API_FRONT_DOMAIN_LIST = [
  {
    front_url: 'www.gama-test.pc.civ.pl',
    program_name: 'Gama Sokołów TEST',
    api_url: getProgramApiUrl('gama', 'test'),
  },
  {
    front_url: 'gama-test.pc.civ.pl',
    program_name: 'Gama Sokołów TEST',
    api_url: getProgramApiUrl('gama', 'test'),
  },
  {
    front_url: 'localhost',
    program_name: 'Gama Sokołów LOCAL',
    api_url: getProgramApiUrl('gama', 'local'),
  },
  {
    front_url: 'karta.gamasokolow.pl',
    program_name: 'Gama Sokołów',
    api_url: getProgramApiUrl('gama', 'prod'),
  },
  {
    front_url: 'cold-lux-local.lvh.me',
    program_name: 'Cold LUX local',
    api_url: getProgramApiUrl('cold', 'test'),
  },
  {
    front_url: 'cold-lux-test-front.pc.civ.pl',
    program_name: 'Cold LUX test',
    api_url: getProgramApiUrl('cold', 'test'),
  },
  {
    front_url: 'program.cold-lux.pl',
    program_name: 'Program - Cold Lux',
    api_url: getProgramApiUrl('cold', 'prod'),
  },
  {
    front_url: 'justynka-test-front.pc.civ.pl',
    program_name: 'Delikatesy Justynka',
    api_url: getProgramApiUrl('justynka', 'prod'),
  },
  {
    front_url: 'program.delikatesyjustynka.pl',
    program_name: 'Delikatesy Justynka',
    api_url: getProgramApiUrl('justynka', 'prod'),
  },
];

/**
 * pobranie domeny z ktorej sie laczy
 * @returns {URL}
 */
function getDomain() {
  let domain = (new URL(window.location.href));
  domain = domain.hostname.replace('www.', '');
  return domain;
}

function getFullApiInfo() {
  const domain = getDomain();
  const api_info = API_FRONT_DOMAIN_LIST.find((x) => x.front_url === domain);
  if (typeof api_info !== 'object') {
    console.error(`Brak konfiguracji api dla domeny ${domain}.Sprawdz plik api.js`);
    return [];
  }
  return api_info;
}

const api_info = getFullApiInfo();

export default {
  api: api_info.api_url,
  api_full: api_info,
};
